import { mapSliceToComponent } from '~/imports/universal-page'
import { UNIVERSAL_FETCH_LINKS } from '~/lib/fetch-links'

import SliceManager from '~/components/SliceManager'

import { getStaticPropsFunction } from '~/data/page-data'

function HomePage({ slices }) {
  return (
    <SliceManager
      mapSliceTypeToComponent={mapSliceToComponent}
      slicesData={slices}
    />
  )
}

export default HomePage

export const getStaticProps = getStaticPropsFunction({
  type: 'home_page',
  fetchLinks: UNIVERSAL_FETCH_LINKS,
})
